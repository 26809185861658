.WaitlistInput input{
    width: 320px;
    height: 50px;
    position: relative;
    background-color:  #131313;
    border-style: solid ;
    border-width: 3px;
    border-color: rgb(97, 97, 97);
    border-radius: 13px;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    padding-left: 1rem;
    color: var(--venU-white);
    font-family: var(--font-Outfit);
    font-size: 16px;
}

.WaitlistInput input:-webkit-autofill{
  -webkit-text-fill-color: var(--venU-white) !important;
  -webkit-box-shadow: 0 0 0px 1000px rgba(54, 50, 65, 1)inset;
  background-clip: content-box !important;
  background-color:  #131313;
  background: #131313;
}



.wrap12 {
    display: grid;
    text-align: left;
    width: 230px;
    margin-bottom: .7rem;
  }
  
  
  .wrap32 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    background: rgba(54, 50, 65, 0.1);
    color: white;
    padding: .15rem;
  }

  .wrap32:hover {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 14px;
    background: var(--gradient-button);
    color: white;
    padding: .15rem;
  }

.waitlist input:hover {
    background-color: rgba(79, 74, 74, 1);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    height: 54px;
    width: 326px;
    position: relative;
    background-color:  rgba(54, 50, 65, 1);
    border-style: solid ;
    border-width: 0px;
    border-radius: 13px;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    padding-left: 1rem;
    color: var(--venU-white);
    font-family: var(--font-Outfit);
    font-size: 16px;
}

input:focus  {
    outline: none;
}
 
.error{
  color: var(--venU-white);
  font-family: outfit;
  text-align: left;
  padding-left: .8rem;
  display: none;
}

input:invalid ~ .check2{
  display: none;
  flex-direction: column;
}


.check2{
  color: rgb(47, 203, 47);
  font-size: 20px;
 
  margin-left: -1.9rem;
  padding-right: .65rem;
  z-index: 2;
  display: grid;
}


@media screen and (max-width: 768px) {

  .WaitlistInput input{
    width: 100%;
}

.wrap12 {
  width: 100%;
}

}

@media screen and (max-height: 700px) {

  .WaitlistInput input{
   height: 40px;
  }

  .wrap12 {
    margin-bottom: .2rem;
  }
  
}