@import url("https://fonts.googleapis.com/css?family=Outfit");

:root{
  --gradient-button: linear-gradient(100.54deg, #7C4EFF 0%, #A662EC 51.56%, #FC5EFF 100%);
  --venU-white: #F6F6F9;
  --nightspot-gradient: linear-gradient(93.17deg, #9E85F9 0%, #CB8CFA 52.6%, #F893FC 100%);
  }



html, body {
  margin: 0;
  padding: 0;
  background-color: #131313; /* Ensure the background color matches your design */
  overflow-x: hidden;
}

