.carousel-item{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
}




.carousel-item-title {
    white-space: pre-line;
    font-size: 48px;
    color: #F3F1F9;
    font-weight: 700;
    width: 100%;
    line-height: 55.2px
}

.carousel-item-description {
    white-space: pre-line;
    font-size: 15px;
    color: #d4d4d4;
    width: 100%;
    font-weight: 400;
}

.overlap{
  position: absolute;
  bottom: 0;
}

.overlap-4{
  position: absolute;
  bottom: 50px;
}

@media screen and (max-width: 400px) {
  .carousel-item img{
    width: 350px;
    height: auto;
  }
  .carousel-item-title {
    font-size: 44px;
    line-height: 50.2px
}

.carousel-item-description{
    font-size: 14px;
}

}