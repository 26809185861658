.infoIcons{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding-right: 4rem;
    max-width: 680px;
}


.infoIcon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 12vw;
    text-align: center;
}

.infoIcon img{
    width: 10vw;
    height: 10vw;
    max-width: 98px;
    max-height: 98px;
}

.infoIcon p{
    color: #FFFFFF;
    font-weight: 400;
    font-size:   clamp(12px, 1vw, 16px);
    line-height: clamp(12px, 1vw, 16px);
    max-width: 12vw;
}