.carousel{
   overflow: hidden;
   display: flex;
   width: 90%;
   flex-direction: column;
   justify-content: center;
}

.inner{ 
    white-space: nowrap;
    transition: transform 0.3s;
    margin-top: -0;
    box-shadow: 0px -10px 20px 5px rgba(0,0,0,0.5);
    position: relative;
}

.carousel-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 10;
}

.carousel-buttons-margin {
    margin-top: -50px;
}


.indicators {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 10px;
}

.indicator-buttons {
    
     border: none;
     cursor: pointer;
     padding: 0;
     color: #ffffff;
}

.indicators > button {
    background: none;
    color: #ffffff;
}

.material-symbols-outlined{
    font-size: 1rem;
    color: #ffffff;
}

.indicator-symbol {
    color: rgb(83, 83, 83);
}

.indicator-symbol-active {
    color: #ffffff;
}

.continue-button { 
    width: 100%;
    height: 60px;
    font: outfit;
    font-size: 1rem;
    color: #F3F1F9;
    background-color: #131313;
    border-radius: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    font-weight: 600;
}


.early-access-button {
    color: #F3F1F9;
    width: 100%;
    height: 60px;
    font-weight: 600;
    font-size: 1rem;
    background: var(--nightspot-gradient);
    border-radius: 15px;
    border-color: rgb(233, 233, 233);
    border-style: none;
}


.buttonContainerCarousel{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

