.desktopEarlyAccess{
    display: flex;
    background: radial-gradient(56.91% 130.14% at 50% 48.17%, #24232D 0%, #131313 100%);
    min-height: 100vh;
    align-items: stretch;
}

.joinCommunity{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: outfit;
    background-image: url(../../assets/backgroundBolt.svg); 
    background-size: cover; 
    background-position: 135% 48.17%;
    position: relative;
    overflow: hidden;
    background-size: 60%;
    background-repeat: no-repeat;
}

.navbarEarlyAccess {
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 2rem
}


.joinCommunityContainer{
    display: flex;
    flex-direction: column;
    padding-left: 4rem;
}

.backButton{
    font-size: 16px;
    background: none;
    border-style: none;
    color: #F3F1F9;
    cursor: pointer;
    padding-bottom: 0rem;
}

.backIcon{
    font-size: 20px;
    padding-right: .1rem;
    vertical-align: top;
}

.joinCommunityContainer h1{
    font-size: 64px;
    color: #F3F1F9;
    font-weight: 700;
    margin: 0;
    padding-bottom: .8rem;
}

.joinCommunityContainer h2{
    font-size: 32px;
    color: #F3F1F9;
    font-weight: 600;
    margin: 0;
    padding-bottom: .5rem;
}

.joinCommunityContainer p{
    font-size: 14px;
    color: #bab9be;
    font-weight: 400;
    margin: 0;
    max-width: 300px;
    padding-bottom: 1rem;
}

.tentyOneCheck{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 340px;
    padding-bottom: 1rem;
}

.termsAndPolicy{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 340px;
    padding-bottom: 1rem;
}


.tentyOneCheck input[type="checkbox"] {
    appearance: none; /* Hide the default checkbox */
    width: 20px;
    height: 20px;
    background-color: #131313;
    border: 2px solid #F3F1F9;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
}


.tentyOneCheck input[type="checkbox"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: #F3F1F9;
    transform: translate(-50%, -50%);
    display: none;
}

.tentyOneCheck input[type="checkbox"]:checked::before {
    display: block;
}


.tentyOneCheck p {
    font-size: 14px;
    font-weight: 400;
    color:#F3F1F9B2;
    padding-bottom: 0rem;
}

.termsAndPolicy input[type="checkbox"] {
    appearance: none; /* Hide the default checkbox */
    width: 20px;
    height: 20px;
    background-color: #131313;
    border: 2px solid #F3F1F9;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
}


.termsAndPolicy input[type="checkbox"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: #F3F1F9;
    transform: translate(-50%, -50%);
    display: none;
}

.termsAndPolicy input[type="checkbox"]:checked::before {
    display: block;
}


.termsAndPolicy p {
    font-size: 14px;
    font-weight: 400;
    color:#F3F1F9B2;
    padding-bottom: 0rem;
}

.underline {
    text-decoration: underline;
    color: #F3F1F9;
    cursor: pointer;
}

.noUnderline { 
    text-decoration: none;
    color: #F3F1F9;
}

.submit {
    max-width: 340px;
    height: 50px;
    border-radius: 12px;
    color: #F3F1F9;
    background: var(--gradient-button);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border-style: none;
}

.submit:disabled{
    color: #F3F1F9B2;
}


.submit:disabled:hover{
    cursor:default ;
}

.signUp{
    display: none;
}

@media screen and (max-width: 1300px) {
    .joinCommunity{
        background-size: 75%;
        background-position: 165% 48.17%;
    }
    
}

@media screen and (max-width: 1040px) {
    .joinCommunity{
        background-size: 80%;
        background-position: 225% 48.17%;
    }
    
}

@media screen and (max-width: 900px) {
    .joinCommunity{
        background-size: 90%;
        background-position: 425% 48.17%;
    }
    
}

@media screen and (max-width: 768px) {
    .joinCommunity{
        flex: 1;
        background-image: url(../../assets/earlyAccesBackground.png); 
        background-repeat: no-repeat;
        background-position: center 30%; 
    }  

    .joinCommunityContainer{
        padding: 1rem;
    }

    .logoContainer{
        flex: 0; 
    }

    .backButton{
        display: none;
    }

    .joinCommunity h2{
        display: none;
    }  
   
    .Join{
        display: none;
    }

    .signUp{
        display: block;
    }
    
    .navbarEarlyAccess {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem !important;
    }

    .socials { 
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .footer{
        display: none;
    }

    
    .joinCommunityContainer h1{
        font-size: 48px;
        line-height: 55px;
        color: #F3F1F9;
        font-weight: 700;
        margin: 0;
        padding-bottom: .8rem;
    }

    .joinCommunityContainer p{
        max-width: 340px;
    }

    .termsAndPolicy{
        max-width: 100%;
    }
    
    .tentyOneCheck{
        max-width: 100%;
    }

    .submit {
        max-width: 100%;
    }
}


@media screen and (max-height: 700px) {
 
    .joinCommunityContainer h1{
        font-size: 47px;
        line-height: 47px;
        padding-bottom: .4rem;
    }

    .joinCommunityContainer p{
        padding-bottom: .6rem;
        font-size: 12px;
    }

    .tentyOneCheck{
        padding-top: .1rem;
        padding-bottom: .1rem;
    }

    .termsAndPolicy{
        padding-bottom: .5rem;
    }

    .joinCommunity{
        background-position: center 40%; 
    }  
    
}