.desktop{
    display: flex;
    background: radial-gradient(56.91% 130.14% at 50% 48.17%, #24232D 0%, #131313 100%);
    min-height: 100vh;
    align-items: stretch;
}

.nightspotInfo{
    flex: 55;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: outfit;
}

.navbar {
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 2rem
}

.socials { 
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.nightspotDetails{
   padding-left: 4rem;
   padding-right: 4rem;
  
}

.nightspotDetails h1{
    font-size: clamp(55px, 5.5vw, 86px); 
    line-height: clamp(50px, 5vw, 86px);
    color: #F3F1F9;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-top: 0;
    padding-top: 1rem;
}

.nightspotDetails p{
    font-size:   clamp(14px, 1.3vw, 24px);
    line-height: clamp(14px, 1.3vw, 24px);
    white-space: normal;
    color: #FFFFFF;
    font-weight: 400;
    max-width: 42vw;
}

.nightspotButtonContainer {
    display: flex;
    width: 48vw;
    max-width: 420px;
    margin-top: 1rem;
    justify-content: space-between;
    align-items: center;
}

.nightspotButtonContainer button{
  font-family: outfit;
  border-style: none;

}

.nightspotButtonContainer button:hover{
   cursor: pointer;
}

.earlyAccessButton {
    border-radius: 15px;
    color: #F3F1F9;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    padding: 20px 40px;
    background: linear-gradient(106.83deg, #9E85F9 0%, #CB8CFA 52.6%, #F893FC 100%);
}

.nightspotButtonContainer a {
    text-decoration: none;
}

.contactButton { 
    height: 40px;
    background: none;
    border-style: none;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    color: #F3F1F9;
    display: flex;
    align-items: center;
}

.footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 120px;
    align-items: center;
    padding-bottom: 1rem;
    padding-left: 4rem;
    padding-top: 1.5rem;
}

.footer p{
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    color: #F3F1F9;
    text-decoration: underline;
    margin: 0;
    white-space: nowrap;
    cursor: pointer;
}

.footer .separator {
    margin: 0 20px; 
    color: #F3F1F9; 
}


.imageCarousel{
    display: flex;
    flex: 45; 
    background-image: url(../../assets/backgroundLightningBolt.svg); 
    background-size: cover; 
    background-position: center; 
    justify-content: space-evenly;
    background-position: left center; 
    height: 100vh; /* Adjust as needed */
    overflow-y: hidden;
    gap: 10px
}


.image-container-left {
    display: flex;
    flex-direction: column;
    animation: verticalScroll 25s linear infinite;
  }
  
  .image-container-left img{
   width: 100%;
   height: auto;
   margin-bottom: 20px;
  }

  .scroll-image-left {
    min-width: clamp(175px, 180px, 238px);; /* Adjust based on your image width */
    height: auto;
  }
  
  @keyframes verticalScroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-3006px); /* Adjust based on the total height of all images */
    }
  }

  .image-container-right {
    display: flex;
    flex-direction: column;
    animation: verticalScrollDown 25s linear infinite;
    padding-right: 1rem;
  }
  
  .image-container-right img{
   width: 100%;
   height: auto;
   margin-bottom: 20px;
  }

  .scroll-image-right {
    min-width: clamp(175px, 180px, 238px); /* Adjust based on your image width */
    height: auto;
  }
  
  @keyframes verticalScrollDown {
    0% {
      transform: translateY(-3006px);
    }
    100% {
      transform: translateY(0); /* Adjust based on the total height of all images */
    }
  }


  @media screen and (max-width: 1210px) {

    .nightspotDetails{
      padding-left: 2rem;
      padding-right: 1rem;
   }

   .navbar {
    padding-left: 1rem
}
  }
