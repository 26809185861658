.age-verification-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #131313;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
  }
  
  .age-verification-content {
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    color: white;
    font-family: outfit, sans-serif;
    font-weight: 600;
    font-size: 28px;
  }

  .age-verification-content .logo {
    position: absolute;
    top: 25px;
    left: 25px;
  }

  .age-verification-content p {
    font-weight: 400;
    font-size: 16px;
  }
  
  .age-verification-content h1 {
    margin-bottom: 20px;
  }
  
  .age-verification-content button {
    padding: 10px 20px;
    font-size: 16px;
    width: 80px;
    height: 44px;
    cursor: pointer;
    border-radius: 13px;
    border-color: #403F41;
    border-width: 1px;
    background-color: #131313;
    border-style: solid;
    color: white;
    transition: transform 0.3s ease;
  }

  .age-verification-content button:hover {
    transform: scale(1.1); /* Enlarge button on hover */
  }
  

  .gradientButton{
    background: linear-gradient(106.83deg, #9E85F9 0%, #CB8CFA 52.6%, #F893FC 100%);
  }




  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px
  }