

.mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    font-family: outfit;
    background-color: #131313;
}

.mobileNavbar{
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    background-color: #131313;
    color: #F3F1F9;
}
